



































import { Component, Prop, Vue } from "vue-property-decorator";
import WebinarVideoTemplateComponent from "@/components/media/content-media-grids/WebinarVideoTemplate.vue";
import { blobDataPath } from "@/util";
import { CarouselMedia, DEFAULT_CAROUSEL_IMAGE_URL, MultiMediaCarouselItem } from "@/types";

@Component({
  components: {
    WebinarVideoTemplateComponent,
  },
  computed: {
    mediaList(): CarouselMedia[] {
      const m: CarouselMedia[] = [];
      return m.concat(this.$props.media);
    }
  },
})
export default class MediaCarouselComponent extends Vue {
  @Prop()
  public media!: CarouselMedia | CarouselMedia[];

  public selectedIndex = 0;
  public mediaType = '';

  public mediaList!: CarouselMedia[];
  public defaultImage = DEFAULT_CAROUSEL_IMAGE_URL;
  public blobDataPath = blobDataPath;

  public showPlayer = false;

  public showPlayerTrigger(): void {
    this.identifyMediaType();
    if (this.mediaList[this.selectedIndex]?.videoUrl) {
      this.showPlayer = true;
    }
  }

  public identifyMediaType(): void {
    if(this.mediaList[this.selectedIndex]?.mediaType === undefined){
      this.mediaType = 'video';
    } else {
      this.mediaType = this.mediaList[this.selectedIndex].mediaType;
    }
  }
}
