var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "video-carousel" },
    [
      _c(
        "v-slide-group",
        {
          attrs: { "show-arrows": "always", mandatory: "" },
          scopedSlots: _vm._u([
            {
              key: "next",
              fn: function() {
                return [
                  _c("v-icon", { attrs: { color: "white" } }, [
                    _vm._v(" mdi-chevron-right ")
                  ])
                ]
              },
              proxy: true
            },
            {
              key: "prev",
              fn: function() {
                return [
                  _c("v-icon", { attrs: { color: "white" } }, [
                    _vm._v(" mdi-chevron-left ")
                  ])
                ]
              },
              proxy: true
            }
          ])
        },
        _vm._l(_vm.carouselItems, function(carouselItem, i) {
          return _c("v-slide-item", {
            key: i,
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var active = ref.active
                    var toggle = ref.toggle
                    return [
                      _c("SlideGroupItemComponent", {
                        attrs: {
                          title: carouselItem.title,
                          date: _vm.formattedDateShort(i),
                          description: carouselItem.description,
                          "image-src": _vm.getThumbnail(i),
                          selected: active
                        },
                        on: {
                          "item-select": function($event) {
                            toggle()
                            _vm.$emit("item-select", i)
                          }
                        }
                      })
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }