var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "ma-4",
      class: { "v-card--selected": _vm.selected },
      attrs: { width: "312", height: "200", outlined: _vm.selected },
      on: {
        click: function($event) {
          return _vm.$emit("item-select")
        }
      }
    },
    [
      _c("v-img", {
        attrs: { src: _vm.imageSrc, width: "100%", height: "100%" }
      }),
      _c("div", { staticClass: "img-content-carosal" }, [
        _c("div", { staticClass: "img-heading" }, [
          _vm._v(" " + _vm._s(_vm.title) + " ")
        ]),
        _vm.date
          ? _c("p", { staticClass: "img-date" }, [
              _vm._v(" " + _vm._s(_vm.date) + " ")
            ])
          : _vm._e(),
        _c("p", { staticClass: "img-description" }, [
          _vm._v(" " + _vm._s(_vm.descriptionWithoutMarkdown) + " ")
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }