var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-carousel",
        {
          staticClass: "rounded-lg",
          attrs: {
            "show-arrows": false,
            "hide-delimiters": _vm.mediaList.length <= 1,
            "hide-delimiter-background": "",
            height: "auto"
          },
          model: {
            value: _vm.selectedIndex,
            callback: function($$v) {
              _vm.selectedIndex = $$v
            },
            expression: "selectedIndex"
          }
        },
        [
          !_vm.mediaList.length
            ? _c("v-carousel-item", {
                attrs: { src: _vm.defaultImage, cover: "" }
              })
            : _vm._e(),
          _vm._l(_vm.mediaList, function(m, i) {
            return _c("v-carousel-item", {
              key: i,
              class: {
                "video-poster-display video-poster-display--medium": m.videoUrl
              },
              attrs: { src: _vm.blobDataPath + m.image.url, cover: "" },
              on: { click: _vm.showPlayerTrigger }
            })
          })
        ],
        2
      ),
      _vm.showPlayer
        ? _c("WebinarVideoTemplateComponent", {
            attrs: {
              "media-type": _vm.mediaType,
              "video-url": _vm.mediaList[_vm.selectedIndex].videoUrl,
              "show-player": _vm.showPlayer
            },
            on: {
              "player-closed": function($event) {
                _vm.showPlayer = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }