var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "text-media-container" }, [
      _c("div", { staticClass: "text-container" }, [
        _c("h2", [_c("v-skeleton-loader", { attrs: { type: "heading" } })], 1),
        _c("p", [_c("v-skeleton-loader", { attrs: { type: "paragraph" } })], 1)
      ]),
      _c(
        "div",
        { staticClass: "media-container" },
        [_c("v-skeleton-loader", { attrs: { type: "card" } })],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "slide-group-container" },
      [
        _c(
          "v-container",
          [
            _c(
              "v-row",
              { staticClass: "overflow-x-hidden flex-nowrap" },
              [
                _vm._l([1, 2, 3, 4], function(item) {
                  return [
                    _c(
                      "v-col",
                      { key: item, attrs: { cols: "12", md: "4", lg: "3" } },
                      [
                        _c("v-skeleton-loader", {
                          attrs: { height: "200", type: "card" }
                        })
                      ],
                      1
                    )
                  ]
                })
              ],
              2
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }