


































import { Component, Prop, Vue } from "vue-property-decorator";

import { CarouselMedia, DEFAULT_CAROUSEL_IMAGE_URL, MultiMediaCarouselItem, SingleMediaCarouselItem } from "@/types";
import { blobDataPath } from "@/util";
import SlideGroupItemComponent from "@/components/media/slide-group/SlideGroupItem.vue";

@Component({
  components: {
    SlideGroupItemComponent,
  }
})
export default class SlideGroupComponent extends Vue {
  @Prop()
  public carouselItems!: (SingleMediaCarouselItem | MultiMediaCarouselItem)[];

  getThumbnail(index: number): string {
    let media: CarouselMedia[] = [];
    media = media.concat(this.carouselItems[index].media);
    if (media.length > 0) {
      return blobDataPath + media[0].image.url;
    }
    return DEFAULT_CAROUSEL_IMAGE_URL;
  } 
  
  formattedDateShort(index: number): string {
    if (("showDate" in this.carouselItems[index])) 
      {
        return new Intl.DateTimeFormat(undefined, {
        month: "long",
        day: "numeric"
      }).formatRange(
        new Date(this.carouselItems[index]["showDate"].startDate),
        new Date(this.carouselItems[index]["showDate"].endDate)
      );
      }
      else 
      {
        return "";
      }
  }


}
