var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "video-player-dialog-container",
      attrs: { width: "75vw" },
      on: {
        input: function(showPlayer) {
          return showPlayer || _vm.$emit("player-closed")
        }
      },
      model: {
        value: _vm.showPlayer,
        callback: function($$v) {
          _vm.showPlayer = $$v
        },
        expression: "showPlayer"
      }
    },
    [
      _vm.mediaType === "video"
        ? _c("iframe", {
            attrs: {
              sandbox:
                "allow-same-origin allow-scripts allow-popups allow-forms",
              src: _vm.videoUrl,
              height: "500",
              frameborder: "0",
              allow:
                "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
              allowfullscreen: "",
              title: "Tr&eacute;ning TEXT"
            }
          })
        : _vm._e(),
      _vm.mediaType !== "video"
        ? _c("v-img", {
            staticClass: "gif-overlay",
            attrs: { src: _vm.videoUrl }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }