































import { Component, Prop, Vue } from "vue-property-decorator";

import WebinarVideoTemplateComponent from "@/components/media/content-media-grids/WebinarVideoTemplate.vue";
import marked from "marked";

@Component({
  components: {
    WebinarVideoTemplateComponent,
  }
})
export default class SlideGroupItemComponent extends Vue {
  @Prop()
  public title!: string;

  @Prop()
  public date!: string;

  @Prop()
  public description!: string;

  @Prop()
  public imageSrc!: string;

  @Prop()
  public selected!: boolean;

  descriptionWithoutMarkdown="";

  mounted(): void{
    const htmlContent= marked(this.description);
    const textwithOuttag= htmlContent.replace(/<[^>]*>/g,'');
    this.descriptionWithoutMarkdown= textwithOuttag;
  }

}
