
























import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class WebinarVideoTemplateComponent extends Vue {
  @Prop()
  public mediaType!: string;

  @Prop()
  public videoUrl!: string;

  @Prop()
  public showPlayer!: boolean;
  
}
